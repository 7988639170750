import React from "react";
import { Container } from "react-bootstrap";

function Login() {
    return (
        <Container>
            <h1>Login</h1>
        </Container>
    );
}

export default Login;
