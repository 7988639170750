import React from "react";
import { Container } from "react-bootstrap";

function Art() {
    // "Nordic Summer Evening" by Richard Bergh
    return (
        <Container>
            <h1>Art</h1>
        </Container>
    );
}

export default Art;
