import React from "react";
import { Container } from "react-bootstrap";

function About() {
    return (
        <Container>
            <h1>About</h1>
        </Container>
    );
}

export default About;
