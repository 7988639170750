import React from "react";
import { Container } from "react-bootstrap";

function Projects() {
    return (
        <Container>
            <h1>Projects</h1>
        </Container>
    );
}

export default Projects;
