import React from "react";
import { Container } from "react-bootstrap";

function Blog() {
    return (
        <Container>
            <h1>Blog</h1>
        </Container>
    );
}

export default Blog;